import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { TwitterScript } from '../../components/TwitterScript';
import { CommunityVideoPreview } from '../../components/CommunityViewPreview';
export const _frontmatter = {
  "commentId": "stephanie-morillo-interview",
  "image": "/images/interviews/stephanie-ama-preview.png",
  "date": "2021-03-12",
  "socialSharingImage": "/images/interviews/stephanie-ama-preview.png",
  "title": "Knowing Your Worth as a Tech Creator with Stephanie Morilllo",
  "summary": "Stephanie Morillo has managed the company blogs at Digital Ocean and GitHub. Today coaches Developer Relations through their technical content strategy.",
  "seoTitle": "Knowing Your Worth as a Tech Creator with Stephanie Morilllo",
  "seoDescription": "Josh W Comeau has worked for Gatsby and Khan Academy, and recently struck out on his own to create his course CSS for JavaScript Developers. Learn how blogging led him to this niche and how Josh thinks about blogging.",
  "tweet": "The fascinating story behind Josh Comeau's blog",
  "author": "monica",
  "interviewImage": "/images/interviews/stephanie-morillo.jpg",
  "interviewName": "Stephanie Morillo",
  "interviewJob": "Author of the Developer's Guide to Content Creation, Developer Content Strategist",
  "tags": ["interview", "blogging"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p><em parentName="p">{`This interview is an excerpt from the `}<Link to="/pro/" mdxType="Link">{`Blogging for Devs Community`}</Link>{` AMA session with Stephanie.`}</em></p>
    <img src="/images/interviews/stephanie-ama-preview.png" alt="Stephanie Morillo" style={{
      "maxWidth": "100%",
      "border": "1px solid #0D0F11",
      "display": "inline-block",
      "margin": "24px auto",
      "borderRadius": "8px"
    }} />
    <p>{`In this interview, Stephanie shares:`}</p>
    <ul>
      <li parentName="ul">{`How she wrote and self-published her book without a massive audience`}</li>
      <li parentName="ul">{`The three types of audiences every blog has and how to identify yours`}</li>
      <li parentName="ul">{`Four ways to get endless content ideas for your blog`}</li>
      <li parentName="ul">{`How to get your work as a tech creator valued by companies`}</li>
      <li parentName="ul">{`Why people and companies try not to pay for content`}</li>
      <li parentName="ul">{`How to measure the ROI of a blog post`}</li>
      <li parentName="ul">{`Tips for motivating engineers in your organization to contribute to the company blog`}</li>
    </ul>
    <p>{`Let's roll ✌️`}</p>
    <hr></hr>
    <h2>{`Big thanks to Stephanie for joining this session!`}</h2>
    <p>{`If you're a member of the `}<Link to="/pro/" mdxType="Link">{`Blogging for Devs Pro Community`}</Link>{`, you've got access to the full, one-hour video session! Click the video to check it out:`}</p>
    <CommunityVideoPreview url="https://community.bloggingfordevs.com/c/video-library/expert-session-with-stephanie-morillo-45m" src="/images/interviews/stephanie-ama-preview.png" alt="AMA Video with Stephanie Morillo" mdxType="CommunityVideoPreview" />
    <TwitterScript mdxType="TwitterScript" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      